let positionInPage: number;
let modulePosition: number;
let positionInModule: number;
let adaptedComponent: object;

const componentWithTeasers = ["teaser-group", "schlagzeilen", "aktuelle-meldungen"];

const componentsWithDataTracking = [
	"schlagzeilen",
	"teaser-group",
	"global-newsletter-signup",
	"newsletter-signup",
	"article-newsletter",
	"aktuelle-meldungen",
	"artikelliste",
	// article inline teasers
	"teaser"
];

export default function useTrackComponents(content: any) {
	positionInPage = 0;
	modulePosition = 0;
	positionInModule = 0;
	return content?.map(addComponentTrackingData);
}

function addComponentTrackingData(component) {
	if (!componentsWithDataTracking.includes(component.component)) {
		return component;
	}
	modulePosition++;
	adaptedComponent = component;
	adaptedComponent.content = {
		...adaptedComponent.content,
		"data-tracking-module": 1,
		"data-tracking": JSON.stringify({
			zone: "main_content",
			module_position: modulePosition,
			module_type: getModuleType(adaptedComponent.component),
			...getTitleAttributes(adaptedComponent),
		}),
	};

	if (adaptedComponent.component === "artikelliste") {
		if (!adaptedComponent.content?.teaserGroup?.teasers) {
			return adaptedComponent;
		}
		adaptedComponent.content.teaserGroup.teasers = addTeaserTrackingData(adaptedComponent.content.teaserGroup.teasers);
		adaptedComponent.content.teaserGroup.teasers = setHasImageInTeasers(
			adaptedComponent.content.teaserGroup.teasers,
			adaptedComponent.content.layout,
		);
		adaptedComponent.content.teaserGroup.teasers = setHasTeaserInTeasers(
			adaptedComponent.content.teaserGroup.teasers,
			adaptedComponent.content.layout,
		);
		positionInModule = 0;

		return adaptedComponent;
	}

	if (adaptedComponent.component === "teaser") {
		adaptedComponent.content.article = addTeaserTrackingData([adaptedComponent.content?.article])[0];
	}

	if (componentWithTeasers.includes(adaptedComponent.component)) {
		if (!adaptedComponent.content?.teasers) {
			return adaptedComponent;
		}

		adaptedComponent.content.teasers = addTeaserTrackingData(adaptedComponent.content.teasers);
		adaptedComponent.content.teasers = setHasImageInTeasers(adaptedComponent.content.teasers, adaptedComponent.content.layout);
		adaptedComponent.content.teasers = setHasTeaserInTeasers(adaptedComponent.content.teasers, adaptedComponent.content.layout);
		positionInModule = 0;

		return adaptedComponent;
	}

	if (!adaptedComponent.content?.teasers) {
		return adaptedComponent;
	}

	adaptedComponent.content.teasers = addTeaserTrackingData(adaptedComponent.content.teasers);
	positionInModule = 0;

	return adaptedComponent;
}

export function addTeaserTrackingData(teasers: object, showModulePosition: boolean = true) {
	return teasers?.map((teaser, index) => {
		return {
			...teaser,
			"data-tracking-module-item": 1,
			"data-tracking": JSON.stringify({
				position_in_page: showModulePosition ? ++positionInPage : -1,
				module_position: showModulePosition ? modulePosition : -1,
				position_in_module: showModulePosition ? ++positionInModule : (index + 1),
				is_plus: teaser.payCategory === "plus",
				has_plus_icon: teaser.payCategory === "plus",
				article_id: teaser.id,
				contentOrigin: teaser.contentOrigin ? teaser.contentOrigin : "currated",
			}),
		};
	});
}

function setHasImageInTeasers(teasers: object, layout?: string) {
	if (layout === "1" || layout === "8") {
		return teasers?.map((teaser) => {
			return {
				...teaser,
				"data-tracking": JSON.stringify({
					...JSON.parse(teaser["data-tracking"]),
					has_Image: true,
				}),
			};
		});
	}
	if (layout === "3" || layout === "4") {
		return teasers?.map((teaser, index) => {
			return {
				...teaser,
				"data-tracking": JSON.stringify({
					...JSON.parse(teaser["data-tracking"]),
					has_Image: index === 0,
				}),
			};
		});
	}
	return teasers?.map((teaser) => {
		return {
			...teaser,
			"data-tracking": JSON.stringify({
				...JSON.parse(teaser["data-tracking"]),
				has_Image: false,
			}),
		};
	});
}

function getModuleType(moduleType: string) {
	const moduleTypeIsContent = ["teaser-group", "schlagzeilen", "aktuelle-meldungen", "artikelliste"];
	const moduleTypeIsNewsletter = ["global-newsletter-signup"];
	const moduleTypeIsMarketing = [""];

	if (moduleType === "") return "";
	if (moduleType === "personalizations") return "recommendation";
	if (moduleTypeIsContent.includes(moduleType)) return "content";
	if (moduleTypeIsNewsletter.includes(moduleType)) return "newsletter";
	if (moduleTypeIsMarketing.includes(moduleType)) return "marketing";
}

function setHasTeaserInTeasers(teasers: object, layout: string) {
	if (layout === "1") {
		return teasers?.map((teaser, index) => {
			return {
				...teaser,
				"data-tracking": JSON.stringify({
					...JSON.parse(teaser["data-tracking"]),
					has_Teaser: index < 5 && teaser?.description?.length > 0,
				}),
			};
		});
	}
	if (["3", "4", "6"].includes(layout)) {
		return teasers?.map((teaser, index) => {
			return {
				...teaser,
				"data-tracking": JSON.stringify({
					...JSON.parse(teaser["data-tracking"]),
					has_Teaser: index === 0 && teaser?.description?.length > 0,
				}),
			};
		});
	}
	return teasers?.map((teaser) => {
		return {
			...teaser,
			"data-tracking": JSON.stringify({
				...JSON.parse(teaser["data-tracking"]),
				has_Teaser: false,
			}),
		};
	});
}

function getTitleAttributes(component: object): object {
	return {
		title_visible: component?.content?.title
			|| component?.content?.formTitle
			|| component?.content?.article?.teaserTitle,
		title_internal: component?.component + "_" + modulePosition,
	};
}
